import React from "react"
import sakuraLogo from "../../static/assets/sakura_logo_300.png"
import { Link } from "gatsby"

const st = {
  maxWidth: "150px",
  maxHeight: "40px",
}

const Logo = props => (
  <div className="site-logo">
    <Link to="/">
      <img src={sakuraLogo} style={st} />
    </Link>
  </div>
)

export default Logo
