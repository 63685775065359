import React, { useState } from "react"
import "../assets/css/style.css"
import img from "../images/img.png"
import sakura from "../images/sakura.png"
import whatsapp from "../images/whatsapp.jpg"
import signal from "../images/signal.jpg"
import instagram from "../images/instagram.jpg"
import fb from "../images/fb.jpg"

import email from "../images/email.jpg"
import location from "../images/location.jpg"

const Footer = () => {
  const [footerContent, setfooterContent] = useState([
  {item: "櫻花目錄", 
  content1: "關於櫻花", 
  link1: "櫻花除甲醛技術", 
  content2: "櫻花專題", 
  link2: "櫻花專題", 
  content3: "櫻花案例", 
  link3: "商業除甲醛案例", 
  content4: "櫻花施工", 
  link4: "櫻花除甲醛施工", 
  content5: "櫻花服務", 
  link5: "櫻花除甲醛服務", 
  content6: "聯絡櫻花",
  link6: "聯絡櫻花"},
  {item: "預約櫻花", button: "立即預約", link: "booking"},
  {item: "櫻花介紹", content1: "使用日本技術除甲醛。", content2: "為了要令每個香港家庭的室內空氣質素提升，令您們擁有安心的居住環境，為您所愛的家人作出最安全保障。"},
  {item: "櫻花條款", 
  content1: "服務條款",
  link1: "服務條款",  
  content2: "收費條款", 
  link2: "服務條款", 
  content3: "預約條款",
  link3: "服務條款"},
  {item: "訂閱櫻花", input: "請輸入你的電郵地址", button: "提交"},
  {item: "聯絡櫻花", 
  signal: "櫻花家居", 
  signalLink: "櫻花家居", 

  whatsapp: "+852 95208808", 
  whatsappLink: "https://api.whatsapp.com/send?phone=85295208808", 
  instagram: "sakura_home_services", 
  instagramLink: "https://instagram.com/sakura_home_services/", 
  fb: "櫻花家居服務 - 專業除甲醛", 
  fbLink: "https://facebook.com/sakurahomeservices", 
  email: "info@sakura-home.com.hk", 
  emailLink: "mailto: info@sakura-home.com.hk", 
  location: "九龍觀塘開源道72號溢財中心1202A室",
  locationLink: "https://www.google.com/maps/dir/?api=1&destination=22.31163%2C114.22631&fbclid=IwAR34bes2Ja4i-esHIFdaRWOdGpAb0VDq8BzgSCokIDykf51zdjCyHixaKTE"},
  {copyright: "COPYRIGHT @ 2021 SAKURA HOME SERVICES"},
  {form: "https://www2.sakura-home.com.hk/email"}
  ]);
  const [footerList, setfooterList] = useState([
  {open: false},
  {open: false},
  {open: false},
  {open: false},
  ]);
const toggleFooterList = index => {
 setfooterList(footerList.map((list, i) => {
  if ( i === index ) {
    list.open= !list.open
  }
    //lists.open = false;
  //}
  return list;
 }))
}
  return (
<div className="footer px-0 pt-0 pb-2">
	<form action={footerContent[7].form} method="POST" className="container pt-5 relative d-none d-md-flex footer row text-white mx-auto pb-3">
	  <div class="col-7 row mx-0 pr-0">
	     <div className="row flex-column col-1-4 mx-0 lists pl-3 pl-lg-5"
	     >
	       <div className="lists-items list row align-self-start col-auto mx-0 px-0 pb-3">
          <img src={sakura} width="20px" height="20px" className="mr-2 align-self-center"/>
		  {footerContent[0].item}
	       </div>
	       <div className="content footer-left-border col">
		  <a href={footerContent[0].link1}>{footerContent[0].content1}</a>
	       </div>
	       <div className="content footer-left-border col">
		  <a href={footerContent[0].link2}>{footerContent[0].content2}</a>
	       </div>
	       <div className="content footer-left-border col">
		  <a href={footerContent[0].link3}>{footerContent[0].content3}</a>
	       </div>
	       <div className="content footer-left-border col">
		  <a href={footerContent[0].link4}>{footerContent[0].content4}</a>
	       </div>
	       <div className="content footer-left-border col">
		  <a href={footerContent[0].link5}>{footerContent[0].content5}</a>
	       </div>
	       <div className="content footer-left-border col-auto">
		  <a href={footerContent[0].link6}>{footerContent[0].content6}</a>
	       </div>
	     </div>
	     <div className="row mx-0 col-1-3 col-lg-6 lists pr-0"
	     >
	       <div className="lists-items list row align-items-center col-12 mx-0 px-0 pb-3">
          <img src={sakura} width="20px" height="20px" className="mr-2"/>
		  {footerContent[2].item}
	       </div>
	       <div className="content footer-left-border col-4 col-md-12">
		  {footerContent[2].content1}
	       </div>
	       <div className="content footer-left-border col-4 col-md-12 line-height-1">
		  {footerContent[2].content2}
	       </div>

	       <div className="lists-items list row align-items-center col-12 mx-0 px-0 mt-3 align-items-center">
          <img src={sakura} width="20px" height="20px" className="mr-2"/>
		  		<span className="pr-1 pr-lg-3 pr-xl-5 py-1 footer-right-border">{footerContent[1].item}</span>
          <a href={footerContent[1].link} className="ml-1 ml-lg-3 ml-xl-5 register-btn btn btn-warning text-white">{footerContent[1].button}</a>
	       </div>

	       <div className="lists-items list row align-items-center col-12 mx-0 px-0 py-3">
          <img src={sakura} width="20px" height="20px" className="mr-2"/>
		  {footerContent[3].item}
	       </div>
	       <div className="content footer-left-border pl-3 pr-0">
	       </div>
	       <div className="content col pl-0 pr-2">
		  <a href={footerContent[3].link1}>{footerContent[3].content1}</a>
	       </div>
	       <div className="content col pl-0 pr-2">
		  <a href={footerContent[3].link2}>{footerContent[3].content2}</a>
	       </div>
	       <div className="content col pl-0 pr-2">
		  <a href={footerContent[3].link3}>{footerContent[3].content3}</a>
	       </div>
	     </div>

	       <div className="list row font-size-10 col-12 mx-0 pl-3 pl-lg-5 pr-0 mt-3">
          {footerContent[6].copyright}
	       </div>
	  </div>
	     <div className="offset-md-1 row align-self-stretch mr-0 col-12 col-md-4 lists"
	     >
	       <div className="lists-items list row align-items-center col-12 mx-0 px-0 mb-3">
          <img src={sakura} width="20px" height="20px" className="mr-2"/>
		  {footerContent[4].item}
	       </div>
               <div className="align-self-start input-group border-pink">
		  <input type="text" name="input_1" className="form-control" placeholder={footerContent[4].input} aria-label="Recipient's username" aria-describedby="button-addon2"/>
		  <div className="input-group-append">
		    <button className=" btn btn-warning text-white" type="submit" id="button-addon2">{footerContent[4].button}</button>
		  </div>
	       </div>
	       <div className="lists-items list row align-items-center col-12 mx-0 px-0 my-3">
          <img src={sakura} width="20px" height="20px" className="mr-2"/>
		  {footerContent[5].item}
	       </div>
	       <div className="content footer-left-border col col-md-12">
		  <a href={footerContent[5].whatsappLink}><img className="mr-2" src={whatsapp} width="20px"/>{footerContent[5].whatsapp}</a>
	       </div>
	       <div className="content footer-left-border col col-md-12">
		  <a href={footerContent[5].instagramLink}><img className="mr-2" src={instagram} width="20px"/>{footerContent[5].instagram}</a>
	       </div>
	       <div className="content footer-left-border col col-md-12">
		  <a href={footerContent[5].fbLink}><img className="mr-2" src={fb} width="20px"/>{footerContent[5].fb}</a>
		  
	       </div>
	       <div className="content footer-left-border col col-md-12">
		  <a href={footerContent[5].emailLink}><img className="mr-2" src={email} width="20px"/>{footerContent[5].email}</a>
	       </div>
	       <div className="content footer-left-border col col-md-12">
		  <a href={footerContent[5].locationLink}><img className="mr-2" src={location} width="20px"/>{footerContent[5].location}</a>
	       </div>
             </div>

	</form>

	<form action={footerContent[7].form} method="POST" className="container d-flex d-md-none footer row mx-0">
	     <div className="row mx-0 px-0 col-12 col-md-3 lists "
	     >
	       <div className="row mx-0 lists-items px-0 list col-12"
               >
          <img src={sakura} width="20px" height="20px" className="align-self-center col-auto pl-0 pr-2"/>
		  {footerContent[4].item}
	       </div>
               <div className="input-group mt-3 border-pink">
		  <input name="input_1" type="text" className="form-control" placeholder={footerContent[4].input} aria-label="Recipient's username" aria-describedby="button-addon2"/>
		  <div className="input-group-append">
		    <button className=" btn btn-warning text-white" type="submit" id="button-addon2">{footerContent[4].button}</button>
		  </div>
	       </div>
             </div>
	     <div className={"row justify-content-between px-0 col-12 col-md-3 mx-0 lists " + (footerList[1].open ? 'open' : '')}
	     >
	       <div className="row mx-0 lists-items px-0 list col-12"
		  key={1}
		  onClick={() => toggleFooterList(1)}
               >
          <img src={sakura} width="20px" height="20px" className="align-self-center col-auto pl-0 pr-2"/>
		  {footerContent[2].item}
	       </div>
	       <div className="lists-contents row mx-0 pl-3 pr-4">
	       <div className="content col-12">
		  {footerContent[2].content1}
	       </div>
	       <div className="content col-12 line-height-1">
		  {footerContent[2].content2}
	       </div>
	       </div>
	     </div>
	     <div className={"row mx-0 px-0 col-12 col-md-3 lists " + (footerList[2].open ? 'open' : '')}
	     >
	       <div className="row mx-0 lists-items px-0 list col-12"
		  key={2}
		  onClick={() => toggleFooterList(2)}
             >
          <img src={sakura} width="20px" height="20px" className="align-self-center col-auto pl-0 pr-2"/>
		  {footerContent[0].item}
	       </div>
	       <div className="lists-contents row mx-0 pl-3 pr-3">
	       <div className="content col-4 pr-0">
		  <a href={footerContent[0].link1}>{footerContent[0].content1}</a>
	       </div>
	       <div className="content col-4 pl-2 pr-0">
		  <a href={footerContent[0].link2}>{footerContent[0].content2}</a>
	       </div>
	       <div className="content col-4 pl-2 pr-0">
		  <a href={footerContent[0].link3}>{footerContent[0].content3}</a>
	       </div>
	       <div className="content col-4 pr-0">
		  <a href={footerContent[0].link4}>{footerContent[0].content4}</a>
	       </div>
	       <div className="content col-4 pl-2 pr-0">
		  <a href={footerContent[0].link5}>{footerContent[0].content5}</a>
	       </div>
	       <div className="content col-4 pl-2 pr-0">
		  <a href={footerContent[0].link6}>{footerContent[0].content6}</a>
	       </div>
	       </div>
	     </div>

	     <div className={"row mx-0 px-0 col-12 col-md-3 lists " + (footerList[3].open ? 'open' : '')}
	     >
	       <div className="row mx-0 lists-items px-0 list col-12"
		  key={3}
		  onClick={() => toggleFooterList(3)}
             >
          <img src={sakura} width="20px" height="20px" className="align-self-center col-auto pl-0 pr-2"/>
		  {footerContent[3].item}
	       </div>
	       <div className="lists-contents row col-12 mx-0 pl-3 pr-3">
	       <div className="content col-4 pl-2 pr-0">
		  <a href={footerContent[3].link1}>{footerContent[3].content1}</a>
	       </div>
	       <div className="content col-4 pl-2 pr-0">
		  <a href={footerContent[3].link2}>{footerContent[3].content2}</a>
	       </div>
	       <div className="content col-4 pl-2 pr-0">
		  <a href={footerContent[3].link3}>{footerContent[3].content3}</a>
	       </div>
	       </div>
	     </div>
             <div className="row mx-0 mt-3 text-center justify-content-center col-12">
               <a href={footerContent[1].link} className="register-btn btn btn-warning text-white">{footerContent[1].button}</a>
	     </div>
             <div className="row mx-0 mt-3 col-12 justify-content-center">
             <a href={footerContent[5].whatsappLink} className="col-auto pl-0 pr-2">
		  <img src={whatsapp} width="20px" height="20px" className=""/></a>
             <a href={footerContent[5].signalLink} className="col-auto pl-0 pr-2">
		  <img src={signal} width="20px" height="20px" className=""/></a>
             <a href={footerContent[5].instagramLink} className="col-auto pl-0 pr-2">
		  <img src={instagram} width="20px" height="20px" className=""/></a>
             <a href={footerContent[5].fbLink} className="col-auto pl-0 pr-2">
		  <img src={fb} width="20px" height="20px" className=""/></a>

             <a href={footerContent[5].emailLink} className="col-auto p-0">
		  <img src={email} width="20px" height="20px" className=""/></a>
	     </div>
             <div className="text-white row align-items-center mx-0 mt-3 col-12 justify-content-center">
		  <a href={footerContent[5].locationLink}><img src={location} width="20px" height="20px" className="col-auto p-0"/>
                  <span className="px-2">{footerContent[5].location}</span>
		  <img src={location} width="20px" height="20px" className="col-auto p-0"/></a>
	     </div>

	</form>
</div>
  )
}

export default Footer
