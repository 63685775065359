import React from "react"
import "../assets/css/style.css"

const Header = ({children}) => {
	return (
	  <header className="site-header">
	    {children}
	  </header>
	)
}

export default Header