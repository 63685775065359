import React, { useState } from "react"
import { Link } from "gatsby"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import Button from "react-bootstrap/Button"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import "../assets/css/style.css"
import header from "../images/header.jpg"
import header_v19 from "../images/headerbanner_v19_2023-12-04.jpg"

import Logo from "./logo"

const Navigation = ({ title }) => {
  const [banner, setbanner] = useState([{ open: true }])
  const toggleFooterList = index => {
    setbanner(
      banner.map((list, i) => {
        list.open = !list.open
        return list
      })
    )
  }
  return (
    <Navbar
      bg="pink"
      expand="lg"
      fixed="top"
      className={"flex-wrap p-0 " + (banner[0].open ? "open" : "")}
    >
      <div
        className={
          "p-0 relative modal-header " + (banner[0].open ? "open" : "")
        }
      >
        <a href="https://wa.me/85228992995?text=你好，我想延續展覽會優惠">
          <img className="banner-img img" src={header_v19} alt="promotion" />
        </a>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => toggleFooterList(0)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Navbar.Brand className="pl-3 py-2">
        <Logo title={title} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-3" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown title="櫻花技術">
            <NavDropdown.Item as="li">
              <Link to="/櫻花除甲醛技術">櫻花除甲醛技術</Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Link to="/櫻花消毒技術">櫻花消毒技術</Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Link to="/櫻花除甲醛施工">櫻花除甲醛施工</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="櫻花服務">
            <NavDropdown.Item as="li">
              <Link to="/櫻花除甲醛服務">櫻花除甲醛服務</Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Link to="/空間消毒服務">空間消毒服務</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as="li">
            <Link to="/櫻花專題">櫻花專題</Link>
          </Nav.Link>
          <Nav.Link as="li">
            <Link to="/櫻花獎項">櫻花獎項</Link>
          </Nav.Link>
          <NavDropdown title="櫻花案例">
            <NavDropdown.Item as="li">
              <Link to="/消毒案例">消毒案例</Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Link to="/商業除甲醛案例">商業除甲醛案例</Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Link to="/住宅除甲醛案例">住宅除甲醛案例</Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Link to="/其他除甲醛案例">其他除甲醛案例</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as="li">
            <Link to="/聯絡櫻花">聯絡櫻花</Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation
