import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  const ldjson1 = JSON.stringify({
    "@context": "http://schema.org",
    "@type": "WebSite",
    "@id": "#website",
    url: siteUrl,
    name: "\u6afb\u82b1\u5bb6\u5c45\u670d\u52d9",
    alternateName: "\u65e5\u672c\u6280\u8853\u6d88\u9664\u7532\u919b",
    /*
    potentialAction: {
      "@type": "SearchAction",
      target: "https://www.sakura-home.com.hk/?s={search_term_string}",
      "query-input": "required name=search_term_string",
    },
    */
  }).replace(/\//g, "\\/")

  const ldjson2 = JSON.stringify({
    "@context": "http://schema.org",
    "@type": "Organization",
    url: seo.url ? seo.url : siteUrl,
    sameAs: [
      "https://www.facebook.com/sakurahomeservice/",
      "https://www.youtube.com/channel/UCXTQPokQmsJ_SmaZTTsp4fQ",
    ],
    "@id": "#organization",
    name: "\u6afb\u82b1\u5bb6\u5c45\u670d\u52d9\u516c\u53f8",
    logo: seo.image,
  }).replace(/\//g, "\\/")

  return ( <Helmet title={seo.title} titleTemplate={titleTemplate}
  defer={false}> <html lang="zh-Hans" class={(pathname === '/lp') ||
  (pathname.indexOf('/lp/') > -1) ? 'lp' : ''}/> <link rel="alternate"
  href={seo.url} hreflang="zh-Hant-HK" /> <link rel="alternate" href={seo.url}
  hreflang="x-default" /> <link rel="canonical" href={seo.url} /> <meta
  name="description" content={seo.description} /> <meta name="image"
  content={seo.image} /> <meta name="google-site-verification"
  content="vNUN8MHyCPcsYHET0-0MtPY3LH4G98zHMlNxlHB59W8"/>

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <script type="application/ld+json">{ldjson1}</script>

      <script type="application/ld+json">{ldjson2}</script>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
